/* Disable responsive design */

.container, .container-fluid {
  min-width: 1024px !important;
}

/* Custom styles */

.bg-navi {
  background-color: #FAFAFA;
}

.form-signin {
  width: 100%;
  max-width: 420px;
}

.footer {
  text-align: center;
  width: 100%;
  height: 65px;
  line-height: 60px;
}

.custom-progressbar {
  position: absolute;
  top: 50%;
  left: 50%;
}

.data-font-size {
  font-size: 18px;
  margin: 0;
}

.color-black {
  color: #1F1F1F;
}
.color-gray {
  color: #535353 !important;
}
.color-blue {
  color: #375489 !important;
}

.color-dark-blue {
  color: #004683 !important;
}

.color-red {
  color: crimson;
}

.color-green {
  color: forestgreen;
}

.text-decoration-underline {
  text-decoration: underline;
}

.mouse-pointer {
  cursor: pointer;
}

.right-side-separator {
  border-right: 1px solid #ddd;
}

.gray-button {
  background: #f7f7f7 !important;
  color: #535353 !important;
  border: 1px solid #535353 !important;
}

.modal-50w {
  width: 400px !important;
}

.modal-40w {
  width: 350px !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-60 {
  width: 60% !important;
}

.forgotPass-modal-body {
  padding: 43px 43px 0 43px !important;
  font-size: 14px;
}

.forgotPass-modal-footer {
  padding: 22px 38px 31px 44px !important;
  border-top: none !important;
}

.forgotPass-modal-invalid-token {
  text-align: center;
  font-size: 14px;
}

.hidden {
  display: none;
}

.border-soft-black {
  border: 1px solid #414a4c;
}

.bg-blue {
  background: #2293e3 !important;
}

.bg-subtitle {
  background: linear-gradient(to bottom, #fdfdfd 0%,#f2f2f2 57%,#e6e6e6 100%);
  border: 1px solid #B7B7B7;
}

.letter-spacing-md {
  letter-spacing: 0.0925em;
}

.centered-label {
  display: flex;
  align-items: center;
}

#custom-searchbar {
  background: transparent;
  border: none;
  border-bottom: 2px solid #d3d3d3;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

#custom-searchbar:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
#custom-searchbar::placeholder {
  text-align: center;
}

.main-header-container {
  border-bottom: #FFBB03 solid 8px;
  background-color: #FFBB03;
}
.main-header {
  background-color: #ffffff;
  border-bottom: 16px solid #4B4942;
}
.main-header .nav-link {
  color: #393939 !important;
}
.remove-border {
  border: none !important;
}

.main-panel {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.2)!important;
  border-radius: 5px;
}

.MuiTableCell-root {
  border-bottom: 1px solid #707070 !important;
}

tbody.MuiTableBody-root {
  border-left: 1px solid #707070; 
  border-right: 2px solid #707070;
}

.csv-error-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}

.csv-error-table {
  display: block;
}

.add-btn {
  background-color: #d67729 !important;  
  width: 200px;
  text-align: center;
}
.all-report-btn {
  width: 200px;
  text-align: center;
}
.alert-admin-btn {
  background-color: #FC6565 !important;
  border: 1px solid #FC6565 !important;
  color: #ffffff !important;
  padding: 5px 15px!important;
}
.btn-blue {
  background-color: #375489 !important;
  border: 1px solid #375489!important;
  color: #fff!important;
}
.btn-alert {
  width: 20% !important;
  color: #fff !important;
  background: #D67729 !important;
  border-right: 3px solid #fff !important;
}
.btn-alert-active {
  width: 20% !important;
  color: #D67729 !important;
  background: #FFF !important;
  border-color: #D67729 !important;
  border-width: 3px 3px 3px 0 !important;
  border-style: solid solid solid none !important;
}
.MuiTypography-body1 {
  font-size: 1.15rem !important;
}
tfoot.MuiTableFooter-root tr td {
  border-bottom: none !important;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
textarea.show, input.show, select.show {
  background: transparent !important;
  border: none;
  resize: none;
}
span.show, pre.show {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 16px;
  display: block;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  padding-left: 0.5em;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  color: #495057;
  word-break: break-all;
  overflow-wrap: break-all;
  white-space: break-spaces;
}
.detail.show .form-group{
  border-bottom: #cccccc 1px solid ;
}
.detail.show .form-group:last-child{
  border-bottom: none !important;
}

/* sort */
.MuiTableSortLabel-icon {
  display: none !important;
}
th[aria-sort=descending] .MuiTableSortLabel-active:after {
  content: '▼';
  margin-top: 12px;
  margin-left: 6px;
}
th[aria-sort=ascending] .MuiTableSortLabel-active:after {
  content: '▲';
  margin-top: 12px;
  margin-left: 6px;
}
div.sort-asc-first {
  color: rgba(0, 0, 0, 0.87);
}
div.sort-asc-first:after {
  content: '▲';
  margin-top: 12px;
  margin-left: 6px;
}
div.sort-desc-first {
  color: rgba(0, 0, 0, 0.87);
}
div.sort-desc-first:after {
  content: '▼';
  margin-top: 12px;
  margin-left: 6px;
}
th[aria-sort=ascending] div.sort-asc-first:after,
th[aria-sort=descending] div.sort-asc-first:after,
th[aria-sort=ascending] div.sort-desc-first:after,
th[aria-sort=descending] div.sort-desc-first:after
{
  content: ''!important;
  margin: 0;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: white !important;
  background-color: #375489 !important;
}

.MuiTableCell-head {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: left !important;
}

.name-row {
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.name-row-facility {
  width: 13em !important;
}
.buttons-row-facility {
  width: 600px;
  margin-left: -36px;
  margin-right: -16px;
  float: right;
}
.battery-header-facility {
  margin-left: -16px;
  margin-right: -16px;
  width: 115px;
}
.zipcode-search {
  margin-top: 2px;
}
/* remove table scroll */
.MuiTable-root {
  overflow: hidden;
}
.modal-body h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.parent-list h5 {
  font-size: 1.1em;
}

.toggle-button {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  width: 100px;
  outline:none !important;
}
.Toastify__toast--default {
  background: #fff;
  color: #000 !important;
  border: 1px solid #333;
}

.input-unit-text {
  min-width: 50px;
  display: inline-block !important;
}

.MuiBreadcrumbs-li a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 13em;
  display: block;
}

.Toastify__toast--error {
  background: #cb4739 !important;
  border-radius: 10px !important;
  box-shadow: #666666 3px 3px 5px !important;
  text-align: center;
}

.Toastify__toast--success {
  background: #49cbb1 !important;
  border-radius: 10px !important;
  text-align: center;
}

.form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
  color: #212529 !important;
}

#facility-list span[data-testid="headcol-3"] {
  width: 120px;
}
.master .MuiTableCell-root {
  padding: 8px !important;
}
.master .MuiTableCell-head {
  text-align: center !important;
}
.master .data-font-size {
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.master .data-font-size.model {
  max-width: 9em;
}
.master .data-font-size.maker {
  max-width: 9em;
}
.master .data-font-size.battery-type {
  max-width: 9em;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.batImg {
  height: 34px;
  margin-left: 10px !important;
  margin-right: 5px !important;
}
.facility-list-no-row {
  width: 44px;
}
.facility-list-name-row {
  width: 238px;
}
.facility-list-client-row {
  width: 351px;
}
.facility-list-buttons-row {
  width: 172px;
}
.facility-list-status-row {
  width: 80px;
}
.btn.status-btn-inactive {
  background-color: #837F7F;
  border-color: #837F7F;
  color: #ffffff;
  font-weight: bold;
}
.btn.status-btn-active {
  background-color: #FFBB03;
  border-color: #FFBB03;
  color: #000000;
  font-weight: bold;
}
.mult-rate-description {
  font-size: 14px;
  margin-left: -50px;
  margin-top: 10px;
}
textarea.form-input {
  width: 26em;
  padding: 0.3em;
}
pre.form-input {
  width: 26em;
  padding: 0.5em;
}
input::placeholder {
  color: #bbbbbb !important;
}
.MuiButton-label {
  font-size: 1rem;
  color: #535353;
}
.dengen-donor-admin-subtitle {
  flex-grow: 1 !important;
  color: #000 !important;
  font-size: 16px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}